require("normalize.css/normalize.css");
require("./styles/index.scss");

import menuJs from "./scripts/menu.js";
import head from "./scripts/head.js";
import footerJs from "./scripts/footer.js";
import homePageJs from "./scripts/home.js";

import 'lazysizes';

