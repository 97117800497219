import ScrollReveal from 'scrollreveal';
import jQuery from "jquery";
import CommonUtil from './util/common-util.js'

jQuery($ => {
    /* for top banner message, can be reused */

    // if (!CommonUtil.getCookie('renamed-shown')) {
    //     $('body').addClass('renamed-shown');
    // }

    // $('.renamed__cross').click(function() {
    //     $(this).parent().hide();
    //     CommonUtil.createCookie('renamed-shown', true, 8760);
    //     $('body').removeClass('renamed-shown');
    // });
});
