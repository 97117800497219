import jQuery from 'jquery';
import { cardSlider } from 'huma-components/scripts/sliders';

jQuery($ => {
	if ($('.page-index').length == 0) {
		return true;
	}

	if($(document).width() > 768){
		$('#video-china').find('.mp4').attr('src','../../media/china-video-1080.mp4')
		$('#video-china').find('.webm').attr('src','../../media/china-video-1080.webm')
	} else {
		$('#video-china').find('.mp4').attr('src','../../media/china-video-720.mp4')
		$('#video-china').find('.webm').attr('src','../../media/china-video-720.webm')
	}

	const videojs = require('video.js');

	//Section 3
	cardSlider({
		slider: '.index-section-3 .slider-container',
		navLi: '.index-section-3 .slider-nav li',
		navSlider: '.index-section-3 .slider-nav ul',
		autoplay: false
	});
});

