import jQuery from 'jquery';

jQuery(($)=>{

  const body = document.body;
  const scrollDown = "scroll-down";
  let lastScroll = 0;
  let changeStateRequest = 0;
  const sensitivity = 2;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown) && currentScroll > 0) {
      if (changeStateRequest > sensitivity) {
        body.classList.add(scrollDown);
        changeStateRequest = 0
      } else {
        changeStateRequest++
      }
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
      if (changeStateRequest > sensitivity) {
        body.classList.remove(scrollDown);
        changeStateRequest = 0
      } else {
        changeStateRequest++
      }
    }

    lastScroll = currentScroll;
  });
})
