export default {
    createCookie,
    getCookie
}

function createCookie(name, value, hours) {
    if (hours) {
      var date = new Date();
      date.setTime(date.getTime()+(hours*60*60*1000));
      var expires = "; expires="+date.toGMTString();
    } else {
      var expires = "";
    }

    document.cookie = name+"="+value+expires+"; path=/";
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}
