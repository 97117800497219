import jQuery from "jquery";
import CommonUtil from './util/common-util.js'

jQuery($ => {
    let nowDate = new Date();
    const $clock = $(".clock");

    const timeZone = {
        "new-york": -5,
        sunnyvale: -8,
        london: 0,
        "shang-hai": 8
    };

    function clock() {
        $clock.each(function() {
            const localTimeStamp = new Date(
                new Date().getTime() +
                    new Date().getTimezoneOffset() * 1000 * 60 +
                    timeZone[$(this).data("time-zone")] * 60 * 1000 * 60
            ).getTime();
            nowDate = new Date(localTimeStamp);
            $(this)
                .find(".hour")
                .css(
                    "transform",
                    "translateX(-50%) " +
                    "rotate(" +
                        ((nowDate.getHours() % 12) * 30 +
                            (nowDate.getMinutes() % 60) / 2) +
                        "deg)"
                );
            $(this)
                .find(".minute")
                .css(
                    "transform",
                    "translateX(-50%) " +
                    "rotate(" + (nowDate.getMinutes() % 60) * 6 + "deg)"
                );
        });
    }

    clock();

    function executeTrackingScripts() {
      let first_src = $('script[data-on-tracking=first]').attr('src');
      $.getScript( first_src, function() {
        let second_code = $('script[data-on-tracking=second]').text();
        eval(second_code);
      });
    }

    if (!CommonUtil.getCookie('cookies-consent')) {
        $('.cookies-consent').show();
    } else {
      if(CommonUtil.getCookie('cookies-consent') === 'accepted') {
        executeTrackingScripts();
      }
    }

    $(".cookies-consent__cross").click(function(e) {
      e.preventDefault();
      $(this)
        .closest('.cookies-consent')
        .hide();
    });

  $(".decline-cookies").click(function(e) {
    e.preventDefault();
    $(this)
      .closest('.cookies-consent')
      .hide();
    CommonUtil.createCookie('cookies-consent', 'denied');
  });

    $(".accept-cookies").click(function(e) {
      e.preventDefault();
      $(this)
        .closest('.cookies-consent')
        .hide();
      CommonUtil.createCookie('cookies-consent', 'accepted', 8760);
      executeTrackingScripts();
    });

    $(document).on("visibilitychange", function() {
        if (this.hidden) {
            $clock.hide();
        } else {
            $clock.show();
            clock();
        }
    });

});
